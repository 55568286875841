// Constants and Utils
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import {
  getIsOrgSeller,
  getIsSubscriptionActive,
  maskCharacter,
} from "../../../common-utils/subscription-utils/SubscriptionUtil";
import { UrlKeys } from "../../../constants/GeneralConstants";
import {
  getFrequencyValue,
  getModifiedPopulation,
  getReachPercentage,
  getReachValueAsString,
} from "../../../utils/ReachAndFrequencyUtils";
import { checkIsSellerUser } from "../../../utils/SellerUtils";
import { useUrlKeyCheck } from "../../utils/hooks/HooksUtil";

// Components
import Rating from "./Rating";

function FrequencySection({ reachByFreq, label }) {
  const hasImpressions = reachByFreq && Object.keys(reachByFreq).length > 0;

  const {
    frequencyReach1,
    frequencyReach2,
    frequencyReach3,
    frequencyReach4,
    frequencyReach5,
  } = hasImpressions ? reachByFreq : {};

  return (
    <div className="col-2">
      <p>{label}</p>
      {!hasImpressions && <p>--</p>}
      {hasImpressions && (
        <>
          <h5> F 1+ : {formatText(toLocaleString(frequencyReach1))}</h5>
          <h5> F 2+ : {formatText(toLocaleString(frequencyReach2))}</h5>
          <h5> F 3+ : {formatText(toLocaleString(frequencyReach3))}</h5>
          <h5> F 4+ : {formatText(toLocaleString(frequencyReach4))}</h5>
          <h5> F 5+ : {formatText(toLocaleString(frequencyReach5))}</h5>
        </>
      )}
    </div>
  );
}

export function MonthlyImpressions({ impressions, duration }) {
  // Checking Org is Subscribed or Not
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();
  const { ots, tgOts } = impressions;

  //Checking if it is from Site-analyser
  const isFromSiteAnalyser = useUrlKeyCheck(UrlKeys.siteAnalyser);

  const finalTotalImpressionsToShow =
    !isSubscribed && isSeller
      ? maskCharacter(ots)
      : formatText(toLocaleString(ots));

  const finalTgImpressionsToShow =
    !isSubscribed && isSeller
      ? maskCharacter(tgOts)
      : formatText(toLocaleString(tgOts));

  return (
    <div className="col-3">
      <p>Impressions ({duration} Days)</p>
      {!isFromSiteAnalyser && <h5>Tg : {finalTgImpressionsToShow}</h5>}
      <h5>Total : {finalTotalImpressionsToShow}</h5>
    </div>
  );
}

export function MediaReach({ reachFrequency = {}, cityPopulation }) {
  const { reach, tgReach } = getReachValueAsString(reachFrequency);
  const reachPercentage = getReachPercentage(cityPopulation, reachFrequency);
  const { tgReachPercent } = reachFrequency || {};

  // Checking Org is Subscribed or Not and is Org Type is Seller
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();

  //Checking if it is from Site-analyser
  const isFromSiteAnalyser = useUrlKeyCheck(UrlKeys.siteAnalyser);

  const finalTgreach =
    !isSubscribed && isSeller
      ? `(*%)`
      : tgReachPercent
      ? tgReachPercent.toFixed(2)
      : "";

  return (
    <div className="col-2">
      <p>
        <span>{"Reach (%)"}</span>
      </p>
      {!isFromSiteAnalyser && (
        <h5>
          Tg : {formatText(tgReach)} {finalTgreach ? `(${finalTgreach}%)` : ""}
        </h5>
      )}
      <h5>
        Total : {formatText(reach)} {reachPercentage}
      </h5>
    </div>
  );
}

export function CityPopulation({ cityPopulation }) {
  const population = getModifiedPopulation(cityPopulation);
  return (
    <div className="col-2">
      <p>
        <span className="d-block">{"City Population"}</span>
      </p>
      <h5>{population}</h5>
    </div>
  );
}

export function MediaFrequency({ reachFrequency }) {
  const {
    frequencyReach1,
    frequencyReach2,
    frequencyReach3,
    frequencyReach4,
    frequencyReach5,
  } = reachFrequency;

  return (
    <FrequencySection
      reachByFreq={{
        frequencyReach1,
        frequencyReach2,
        frequencyReach3,
        frequencyReach4,
        frequencyReach5,
      }}
      label="Frequency - Reach"
    />
  );
}

export function MediaTgFrequency({ reachFrequency }) {
  const {
    frequencyTgReach1,
    frequencyTgReach2,
    frequencyTgReach3,
    frequencyTgReach4,
    frequencyTgReach5,
  } = reachFrequency;

  return (
    <FrequencySection
      reachByFreq={{
        frequencyReach1: frequencyTgReach1,
        frequencyReach2: frequencyTgReach2,
        frequencyReach3: frequencyTgReach3,
        frequencyReach4: frequencyTgReach4,
        frequencyReach5: frequencyTgReach5,
      }}
      label="Frequency - Tg Reach"
    />
  );
}

export function OtherDetails({ ltsRating, dwellTimeSummary, reachFrequency }) {
  const { averageTime } = dwellTimeSummary ?? {};
  const frequencyValue = getFrequencyValue(reachFrequency);

  return (
    <div className="col-3 mt-2">
      <p>Other Details</p>
      <h5> LTS rating : {formatText(ltsRating)}</h5>
      <h5>
        Avg. Dwell Time :
        {averageTime ? Math.floor(averageTime) : formatText(averageTime)} sec
      </h5>
      <h5>Frequency : {frequencyValue} </h5>
    </div>
  );
}

// ArAC ==> Ar(AspectRation), A(Area), C(Clutter)
export function ArAC_ratings({ ltsSummary }) {
  const {
    areaIndex,
    clutterIndex,
    aspectRatioIndex,
    elevationIndex,
    tiltIndex,
  } = ltsSummary || {};

  const ltsInfoConstants = [
    {
      label: "Aspect ratio",
      value: aspectRatioIndex,
    },
    {
      label: "Area",
      value: areaIndex,
    },
    {
      label: "Clutter",
      value: clutterIndex,
    },
    {
      label: "Elevation",
      value: elevationIndex,
    },
    {
      label: "Tilt",
      value: tiltIndex,
    },
  ];

  return (
    <div className="col-3 mt-2">
      {ltsInfoConstants.map((eachInfo) => (
        <div key={eachInfo.label} className="mb-2">
          <Rating displayName={eachInfo.label} value={eachInfo.value} />
        </div>
      ))}
    </div>
  );
}
