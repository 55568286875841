import { toLocaleString } from "../common-utils/string-utils/StringUtils";
import {
  getIsSubscriptionActive,
  maskCharacter,
} from "../common-utils/subscription-utils/SubscriptionUtil";
import { checkIsSellerUser } from "./SellerUtils";

/**
 * @param {*} reachFrequency
 * @returns 1. Not-Subscribed -> whether we have data or not, showing 4-stars.
 * 2. Subscribed -> !frequency || frequency === 0.0 -> show "NA"
 * 3. Other Cases -> show Frequency-Value.
 */
export function getFrequencyValue(reachFrequency) {
  const { frequency } = reachFrequency ?? {};

  // Checking Org is Subscribed or Not
  const isSubscribed = getIsSubscriptionActive();

  if (!isSubscribed) {
    return "****";
  } else if (!frequency || frequency === 0.0) {
    return "NA";
  } else {
    return Math.ceil(frequency);
  }
}

/**
 * @param {*} cityPopulation
 * @param {*} reachFrequency
 * @returns 1. Not-Subscribed and no-reach -> showing nothing.
 * 2. Not-Subscribed and have-Reach -> showing (*%)
 * 3. Subscribed and no-reach -> showing nothing.
 * 4. Subscribed and no-city-population -> showing (NA)
 * 5. Subscribed and have-reach -> showing (some%)
 */
export function getReachPercentage(cityPopulation, reachFrequency) {
  const { reach } = reachFrequency ?? {};

  // Checking Org is Subscribed or Not
  const isSubscribed = getIsSubscriptionActive();

  if (!isSubscribed && !reach) {
    return "";
  } else if (!isSubscribed && reach) {
    return `(*%)`;
  } else if (!reach) {
    return "";
  } else if (!cityPopulation) {
    return "(NA)";
  }

  // final return
  const reachPercentage = ((reach * 100) / cityPopulation).toFixed(2);
  return `(${reachPercentage}%)`;
}

/**
 * @param {*} reachFrequency
 * @returns 1. Not-Subscribed and no-reach -> showing "4-stars"
 * 2. Not-Subscribed and have-reach -> showing "masked value"
 * 3. Subscribed and no-reach -> showing "NA"
 * 4. other cases -> showing "ReachValue"
 */
export function getReachValueAsString(reachFrequency) {
  const { reach, tgReach } = reachFrequency ?? {};

  // Checking Org is Subscribed or Not and is seller
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();

  if (!isSubscribed && isSeller && !reach) {
    return "****";
  } else if (!isSubscribed && isSeller && reach) {
    return { reach: maskCharacter(reach), tgReach: maskCharacter(tgReach) };
  } else if (!reach) {
    return { reach: "NA", tgReach: "NA" };
  } else {
    return { reach: toLocaleString(reach), tgReach: toLocaleString(tgReach) };
  }
}

/**
 * @param {*} population
 * @returns 1. No-Population -> showing "NA"
 * 2. Have-Population -> showing Population
 */
export function getModifiedPopulation(population) {
  return !population ? "NA" : toLocaleString(population);
}
