// Constants and Utils
import { MediaLitStatus } from "../constants/GeneralConstants";
import { toLocaleString } from "../common-utils/string-utils/StringUtils";
import {
  getIsSubscriptionActive,
  IsTrafficOtsMethodology,
  maskCharacter,
} from "../common-utils/subscription-utils/SubscriptionUtil";

import { checkIsSellerUser } from "./SellerUtils";

export function getMediaOts(media) {
  const otsSummary = media.otsSummary || {};

  if (Object.keys(otsSummary).length < 1) {
    return null;
  }

  const isTrafficMethodology = IsTrafficOtsMethodology();

  if (isTrafficMethodology) {
    const ots =
      media.litStatus && media.litStatus !== MediaLitStatus.NOT_LIT
        ? otsSummary.otsLit
        : otsSummary.ots;

    const tgOts =
      media.litStatus && media.litStatus !== MediaLitStatus.NOT_LIT
        ? otsSummary.targetOtsLit
        : otsSummary.targetOts;
    return { ots, tgOts };
  }

  // TODO:update when we have expMobileTgOts right now we are using only traffic ots
  const ots = otsSummary.expMobileOts;
  return { ots };
}

/**
 * this function  will check if org is Subscribed or Not and is seller, and checks the ots methodology org
 * is subscribed to and returns the totalOts accordingly
 *
 */
export function getOtsTotal(summary = {}) {
  if (Object.keys(summary).length < 1) {
    return null;
  }

  // in campaign summary we are getting  mobileOtsTotal as mobilityOtsTotal
  // in cty summary  we are getting it as mobileOtsTotal,so i am destructuring both the values
  //  and using the value which is defined
  const { otsTotal, mobilityOtsTotal, mobileOtsTotal, tgOtsTotal } = summary;

  // Checking Org is Subscribed or Not and is seller
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();
  // to check  if org is subscribed to trffic ots methodology
  const isTrafficMethodology = IsTrafficOtsMethodology();

  // we are sending the otsTotal based on ots methodology org is  subscribed to
  if (isTrafficMethodology) {
    const totalTrafficOts =
      !isSubscribed && isSeller
        ? maskCharacter(otsTotal)
        : toLocaleString(otsTotal);

    const totalTgOts =
      !isSubscribed && isSeller
        ? maskCharacter(tgOtsTotal)
        : toLocaleString(tgOtsTotal);
    return { otsTotal: totalTrafficOts, tgOtsTotal: totalTgOts };
  }

  // TODO:update when we have mobileOtsTgTotal right now we are using only traffic ots
  // here i am using  mobilityOtsTotal if it is  present ,otherwise i am using mobileOtsTotal
  const totalMobileOts =
    !isSubscribed && isSeller
      ? maskCharacter(mobilityOtsTotal ?? mobileOtsTotal)
      : toLocaleString(mobilityOtsTotal ?? mobileOtsTotal);
  return { otsTotal: totalMobileOts };
}

export function getMediaLtsRating(ltsSummary) {
  const ltsRating = ltsSummary?.lts
    ? (ltsSummary.lts * 5).toFixed(2) + "/5"
    : "";
  return ltsRating;
}
